import { useState, useEffect } from "react";
import axios from "axios";
import Echo from "laravel-echo";
import notify from "../assets/notification.mp3";
import serverPath from "../components/config";

const useOrderData = () => {
  const [newOrders, setNewOrders] = useState([]);
  const [inProgress, setInProgress] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [audio] = useState(new Audio(notify));
  

  const playAudio = () => {
    audio.load();
    audio.play();
  };

  const fillData = async () => {
    try {
      const [responseOne, responseTwo] = await Promise.all([
        axios.get(`${serverPath}/api/new_orders`, {
          headers: {
            Accept: "application/json",
          },
        }),
        axios.get(`${serverPath}/api/inProgress_orders`, {
          headers: {
            Accept: "application/json",
          },
        }),
      ]);

      const newOrdersData = responseOne.data.order;
      const inProgressData = responseTwo.data.order;

      setNewOrders(newOrdersData);
      setInProgress(inProgressData);
    } catch (error) {
      console.error("Error fetching data: ", error);
      // Handle error, show alert, etc.
    }
  };

  useEffect(() => {
    fillData();
    // window.Echo = new Echo({
    //   broadcaster: "socket.io",
    //   host: "https://echo.buffet.top2top.net",
    // });

    // const channel = window.Echo.channel("orders-channel");
    // channel.listen(".OrdersEvent", (data) => {
    //   handleUpdate(data);
    // });

    // const channelCancel = window.Echo.channel("make-cancel-channel");
    // channelCancel.listen(".MakeOrCancelOrder", (data) => {
    //   handleCancelOrder(data);
    // });

    // Clean-up effect
    return () => {
      // Unsubscribe from channels or perform clean-up if needed
    };
  }, []);

  const handleCancelOrder = (data) => {
    playAudio();

    if (data.status === "Cancelled") {
      setNewOrders((prevOrders) =>
        prevOrders.filter((item) => item.id !== data.order_id)
      );
    }
  };

  const handleUpdate = (data) => {
    playAudio();

    if (data.status === "Pending") {
      setNewOrders((prevOrders) => [...prevOrders, data]);
    } else if (data.status === "Completed") {
      setInProgress((prevInProgress) =>
        prevInProgress.filter((item) => item.id !== data.id)
      );
    } else {
      setInProgress((prevInProgress) => [...prevInProgress, data]);
      setNewOrders((prevOrders) =>
        prevOrders.filter((item) => item.id !== data.id)
      );
    }
  };

  const deliveredItem = (id) => {
    setInProgress((prevInProgress) =>
      prevInProgress.filter((item) => item.id !== id)
    );
  };

  const approvedItem = (id) => {
    setNewOrders((prevOrders) => prevOrders.filter((item) => item.id !== id));
  };

  const showErr = (msg) => {
    if (msg) {
      setShowAlert(true);
      setErrMsg(msg);
    }
  };

  const hideErr = () => {
    setShowAlert(false);
  };

  return {
    newOrders,
    inProgress,
    showAlert,
    errMsg,
    fillData,
    deliveredItem,
    approvedItem,
    showErr,
    hideErr,
    setShowAlert,
  };
};

export default useOrderData;
